<template>
 <CCard>
  <CCardBody>
     <div class="clearfix" >
      <button type="button" class="btn btn-primary float-xl-right"  @click="navToNewSymptoms">New Admin</button>
    </div> 

    <CDataTable :items="items"  
    :fields="fields"
     striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
        ref="vuetable"
      pagination>
      
       <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>
     <template #mobile="{item}" >
        
        <td  class='font-weight-bold'
          v-if="item.mobile"
        >{{ item.mobile }}</td>
        <td v-else>--</td>
       
      </template>

      <template #createdTime="{item}" >
        
        <td  class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
       
      </template>
     
      <template #departmentName="{item}" >
        
        <td  class='font-weight-bold'
          v-if="item.departmentName"
        >{{ item.departmentName }}</td>
        <td v-else>--</td>
       
      </template>
     
       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                  <CDropdownItem :to="{name: 'CMSUser', params: {uid: item.uid, mode: 'view'}}">View </CDropdownItem>
               
                 <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteUser(item.uid)"
                    ></ConfirmationModal>

                </CDropdown>

        
        </td>
      </template>
      
      </CDataTable>
  </CCardBody>
   <div ref="modalArea"></div>
 </CCard>
</template>

<script>
import ConfirmationModal from "../../containers/ConfirmationModal";
const fields = [
  // { key: 'username', label: 'Name', _classes: 'font-weight-bold',_style: "min-width:200px" },
   { key: 'index',_style: "min-width:200px",_classes: 'font-weight-bold', },
   { key: 'firstName',  _style: "min-width:200px",_classes: 'font-weight-bold', },
    { key: 'lastName',  _style: "min-width:200px",_classes: 'font-weight-bold', },
        { key: 'mobile',  _style: "min-width:200px",_classes: 'font-weight-bold', },
         { key: 'email',  _style: "min-width:200px",_classes: 'font-weight-bold', },
        // { key: 'status' , _classes: 'font-weight-bold',_style: "min-width:200px"},
         { key: 'departmentName' ,_style: "min-width:200px",_classes: 'font-weight-bold', },
        { key: 'authority' ,label: "Role", _style: "min-width:200px",_classes: 'font-weight-bold',},
        { key: 'createdTime',label: "Created Date & Time", _style: "min-width:200px", _classes: 'font-weight-bold' },
        { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
export default {
  name: "CMSUsers",
   components: {
    ConfirmationModal,
   },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getUsers() {
       this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/all/users?authority=ROLE_ADMIN")
        .then((response) => response.json())
        .then((data) => (this.items = data,
        console.log("this.items::",this.items)));
    },
    deleteUser(uid) {
      console.log("delete called with", uid);
       this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/user/" +
         uid
      )
        .then((response) => response.json())
        .then((data) => {
          this.getUsers();
             this.$nextTick(() => {
             //this.$refs.vuetable.refresh();
           });
         //  window.location.reload();
        });
    },
    navToNewSymptoms(){
     if( this.$store.state.roles=="ROLE_SUPER_ADMIN"){
       this.$router.push({ name: "CMSUser", params: { mode: "new" } });
       } else{
         alert("This is for Only Super Admin Users");
       }
   // this.$router.push({ name: "CMSUser", params: { mode: "new" } });
    }
  },
  mounted() {
    this.getUsers();
  },
};
</script>